import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Markdown from 'react-markdown'
import ImageGallery from "react-image-gallery";

import './App.css';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
      original: "/wedding/B9341598.jpg",
      thumbnail: "/wedding/B9341598.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341617.jpg",
      thumbnail: "/wedding/B9341617.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341680.jpg",
      thumbnail: "/wedding/B9341680.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341729.jpg",
      thumbnail: "/wedding/B9341729.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341731.jpg",
      thumbnail: "/wedding/B9341731.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341739.jpg",
      thumbnail: "/wedding/B9341739.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341742.jpg",
      thumbnail: "/wedding/B9341742.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341744.jpg",
      thumbnail: "/wedding/B9341744.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341759.jpg",
      thumbnail: "/wedding/B9341759.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341761.jpg",
      thumbnail: "/wedding/B9341761.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341765.jpg",
      thumbnail: "/wedding/B9341765.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341767.jpg",
      thumbnail: "/wedding/B9341767.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341787.jpg",
      thumbnail: "/wedding/B9341787.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341814-2.jpg",
      thumbnail: "/wedding/B9341814-2.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341814.jpg",
      thumbnail: "/wedding/B9341814.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341833.jpg",
      thumbnail: "/wedding/B9341833.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341847.jpg",
      thumbnail: "/wedding/B9341847.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341858.jpg",
      thumbnail: "/wedding/B9341858.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341862.jpg",
      thumbnail: "/wedding/B9341862.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341880.jpg",
      thumbnail: "/wedding/B9341880.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341881.jpg",
      thumbnail: "/wedding/B9341881.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341893.jpg",
      thumbnail: "/wedding/B9341893.jpg",
      thumbnailWidth: 100,
    },
    {
      original: "/wedding/B9341902.jpg",
      thumbnail: "/wedding/B9341902.jpg",
      thumbnailWidth: 100,
    },
  ];


const textDE = `
Liebe Hochzeitsgäste,

wir danken euch von Herzen für die wunderschöne Feier!
Der Tag war unglaublich harmonisch und erfüllend.
Wir hätten ihn uns nicht schöner vorstellen können.
Die Eindrücke klingen immer noch nach und machen uns sehr glücklich.

Auch wurden wir von euch reichlich beschenkt und es gab so schöne Musik und Beiträge…
wir sind wirklich sehr gerührt!
Bitte habt ein wenig Geduld mit uns, da wir uns noch bei jedem Einzelnen von euch persönlich bedanken möchten.

Falls ihr schöne Fotos oder Videos von unserer Hochzeit gemacht habt,
würden wir uns sehr freuen, wenn ihr diese unter folgendem Link hochladen könntet:

[Dropbox Upload](https://www.dropbox.com/request/TI4OHVmO2PHvJbxaK3nb)

Nochmals vielen Dank für alles! Ihr habt unseren Tag unvergesslich gemacht. Wir melden uns nochmal bei Euch einzeln und mit weiteren Fotos und Videos auf der Webseite.

Ganz liebe Grüße,  
Carolina & Richard
`;

const textEN = `
Dear wedding guests,

we thank you from the bottom of our hearts for the wonderful celebration!
The day was incredibly harmonious and fulfilling. We could not have imagined it any better.
The impressions still resonate and make us very happy.

You also gave us plenty of gifts and there was such beautiful music and contributions…
we are really very touched! Please be patient with us, as we would like to thank each of you personally.

If you have taken nice photos or videos of our wedding,
we would be very happy if you could upload them to the following link:

[Dropbox Upload](https://www.dropbox.com/request/TI4OHVmO2PHvJbxaK3nb)

Thank you again for everything! You made our day unforgettable. We will get back to you individually and with more photos and videos on the website.

Warm regards,  
Carolina & Richard
`;

const thanksDE = 'Ganz lieben Dank!';
const thanksEN = 'Thank you so much!';

const disclaimerDE = 'Die Fotos sind eine Erstauswahl unseres Fotografen [Shai Levy](https://shai.ws/). Bitte nicht öffentlich teilen. Thank you Shai!!! ❤';
const disclaimerEN = 'The photos are a first selection by our photographer [Shai Levy](https://shai.ws/). Please do not share publicly. Thank you Shai!!! ❤';

function Gratitude({lang}) {

return (
<Container fluid className="p-3 container main-content rounded bg-white">
    <header className='py-3 mb-4 border-bottom'>
    <div className='container d-flex flex-wrap justify-content-center'>
        <h1 className="header break-word">{lang === 'de' ? thanksDE : thanksEN} <span style={{color:"#e00000"}}>❤</span></h1>
    </div>
    <div className='container d-flex flex-wrap justify-content-center'>
        <h3 className="subheader"></h3>
    </div>
    </header>
    <section>
        <Markdown>{lang === 'de' ? textDE : textEN}</Markdown>
    </section>
    <section>
        <ImageGallery items={images} />
    </section>
    <section>
        <Markdown>{lang === 'de' ? disclaimerDE : disclaimerEN}</Markdown>
    </section>
</Container>
);
};

export default Gratitude;
