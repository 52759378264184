import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Info from './Info';
import Gratitude from './Gratitude';
import invites from './invites';
import 'bootstrap/dist/css/bootstrap.min.css';

import reportWebVitals from './reportWebVitals';

document.body.classList.add('bg-dark');

const params = new URLSearchParams(window.location.search);
const guestid = params.get('g');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ guestid in invites.guests ? <App guestid={guestid} /> : <h1 className="text-center text-white">Invalid Link!</h1> } />
        <Route path="info" element={<Info lang="de" />} />
        <Route path="info/de" element={<Info lang="de" />} />
        <Route path="info/en" element={<Info lang="en" />} />
        <Route path="thankyou" element={<Gratitude lang="en" />} />
        <Route path="dank" element={<Gratitude lang="de" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
