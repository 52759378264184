import React, { } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

function ParticipationSelector({ id, time, program, adults, kids, maxAdults, maxKids, lang, disabled=false, onSelectionChange}) {

    const handleAdultsChange = (event) => {
        onSelectionChange({ [id]: {adults: parseInt(event.target.value), kids: kids} });
    };
    const handleKidsChange = (event) => {
        onSelectionChange({ [id]: {adults: adults, kids: parseInt(event.target.value)} });
    };

    const personsLabel_de = kids === 0 ? 'Personen:' : 'Erwachsene:';
    const personsLabel_en = kids === 0 ? 'Persons:' : 'Adults:';

    return (
        <Container>
        <Row>
            <Col xs={12} md={12}>
            <Form.Label><strong>{time} {program}</strong></Form.Label>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
            <InputGroup className="mb-3">
                <InputGroup.Text>{lang === 'de' ? personsLabel_de : personsLabel_en}</InputGroup.Text>
                <Form.Select
                    aria-label={lang === 'de' ? "Anzahl Personen:" : "Number of persons:"} 
                    disabled={disabled}
                    value={adults}
                    onChange={handleAdultsChange}>
                    {Array.from({ length: maxAdults + 1 }, (_, index) => (
                        <option key={index} value={index}>{index}</option>
                    ))}
                </Form.Select>
            </InputGroup>
            </Col>
        {(maxKids > 0) && (
            <Col xs={12} md={6}>
            <InputGroup className="mb-3">
                <InputGroup.Text>{lang === 'de' ? "Kinder:" : "Kids:"}</InputGroup.Text>
                <Form.Select
                    aria-label={lang === 'de' ? "Anzahl Kinder:" : "Number of kids:"}
                    disabled={disabled} 
                    value={kids}
                    onChange={handleKidsChange}>
                    <option>{lang === 'de' ? 'Auswahl' : 'select'}</option>
                    {Array.from({ length: maxKids + 1 }, (_, index) => (
                        <option key={index} value={index}>{index}</option>
                    ))}
                    </Form.Select>
            </InputGroup>
            </Col>
        )}
        </Row>
        </Container>
    );
}

export default ParticipationSelector;
