import React from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'

import "leaflet/dist/leaflet.css";

const MapLocation = () => {
    return (
    <MapContainer center={[52.37509215558757, 13.051783287026744]} zoom={12} style={{ height: '360px', width: '100%', marginTop: '1.5em', marginBottom: '1.5em' }}>
        <TileLayer
            url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=CGLKLXgGBCaF4TgADuuJQWgnXbWAqV45Cg2PUEKvG4CO4McwpacvK2EmduxE9twl"
            attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[52.38849106654319, 13.087675424584853]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
            <Tooltip direction="bottom" permanent><b>13:00 Alte Neuendorfer Kirche</b></Tooltip>
        </Marker>
        <Marker position={[52.3498039800501, 13.001451169880104]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
        <Tooltip direction="bottom" permanent><b>15:00 Kavalierhaus Caputh</b></Tooltip>
        </Marker>
    </MapContainer>
    );
};

export default MapLocation;
