import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Markdown from 'react-markdown'

import MapLocation from './snippets/maplocation';
import ParticipationSelector from './snippets/participation_selector';

import invites from './invites.json';
import './App.css';


function App({guestid}) {

  const guest = invites.guests[guestid]
  const DE = guest.language === 'de';

  const defaultRSVP = {
    ceremony: { adults: guest.num_adults, kids: guest.num_kids }, 
    coffee: { adults: guest.num_adults, kids: guest.num_kids }, 
    dinner: { adults: guest.num_adults, kids: guest.num_kids }, 
    contribution: { type: "none", duration: 0, description: "" },
    diet: "", song: "",
    transport: "none",
    transport_seats: 0,
    finalized: false
  };

  const [participation, setParticipation] = useState('yes');
  const [rsvp, setRsvp] = useState(defaultRSVP);
  const [showResult, setShowResult] = useState('');

  const handleParticipationChange = (event) => {
    setParticipation(event.target.id);
    const setting = event.target.id === 'yes' ? 
                      { adults: guest.num_adults, kids: guest.num_kids } :
                      { adults: 0, kids: 0 };
    const newRsvp = { ...rsvp, ceremony: setting, coffee: setting, dinner: setting, finalized: false };
    setRsvp(newRsvp);
    sendRSVP(newRsvp);
    setShowResult('');
  };

  const handlePersonsChange = (event) => {
    const newRsvp = { ...rsvp, finalized: false, ...event };
    sendRSVP(newRsvp);
    setRsvp(newRsvp);
    setShowResult('');
  };

  const handleContributionsChange = (event, send) => {
    // the id of the field determines the key in the rsvp object
    const [key, sub] = event.target.id.split("_");
    var newRsvp = { ...rsvp, finalized: false };
    const intValue = parseInt(event.target.value);
    newRsvp[key][sub] = isNaN(intValue) ? event.target.value : intValue;
    if (send === true)
      sendRSVP(newRsvp);
    setRsvp(newRsvp);
    setShowResult('');
  }

  const handleTransportNeedsChanged = (event) => {
    const newRsvp = { ...rsvp, transport: event.target.value, finalized: false };
    sendRSVP(newRsvp);
    setRsvp(newRsvp);
    setShowResult('');
  }

  const handleTransportSeatsChanged = (event) => {
    const newRsvp = { ...rsvp, transport_seats: parseInt(event.target.value), finalized: false};
    sendRSVP(newRsvp);
    setRsvp(newRsvp);
    setShowResult('');
  }

  const handleTextChange = (event, field) => {
    const newRsvp = { ...rsvp, [field]: event.target.value, finalized: false };
    setRsvp(newRsvp);
    setShowResult('');
  }

  const T = (the_text) => {
    if (DE) {
      if (typeof the_text.de === 'string') {
        return the_text.de;
      } else if (typeof the_text.de === 'object' && ('plural' in the_text.de || 'singular' in the_text.de)) {
        return guest.num_adults > 1 ? the_text.de.plural : the_text.de.singular;
      } else {
        return '';
      }
    } else {
      return the_text.en;
    }
  }

  const fetchRSVP = async () => {
    try {
      const response = await fetch(`rsvp?guest=${guestid}`);
      if (response.ok) {
        const result = await response.json();
        //console.log('RSVP data fetched:', result);
        setRsvp(result);
        setParticipation((result.ceremony.adults + result.coffee.adults + result.dinner.adults) > 0 ? 'yes' : 'no');
      }
    } catch (error) {
      console.error('Error fetching data');
    }
  };

  const sendRSVP = async (newRsvp) => {
    try {
      const response = await fetch('rsvp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({[guestid] : newRsvp}),
      });
      const result = await response.json();
      setShowResult('ok');
      //console.log('RSVP successfully set:', result, rsvp);
    } catch (error) {
      setShowResult('failed');
      console.error('Error sending data.');
    }
  }

  useEffect(() => {
    //console.log('Fetching RSVP data');
    fetchRSVP();
  }, []);

  const dom = (
    <Container fluid className="p-3 container main-content rounded bg-white">
      <header className='py-3 mb-4 border-bottom'>
        <div className='container d-flex flex-wrap justify-content-center'>
          <h1 className="header break-word">{DE ? (<>Hochzeits&shy;einladungs&shy;formular A-38</>) : 'Wedding invitation form A-38'}</h1>
        </div>
        <div className='container d-flex flex-wrap justify-content-center'>
          <h3 className="subheader">{DE ? 'für Freitag, den 8. November 2024' : 'for November 8, 2024'}</h3>
        </div>
      </header>
      
      <section>
        <Markdown>{guest.greeting + '\n\n' + T(invites.texts.greeting)}</Markdown>
      </section>
      <section>
        <Row>
          <Col className="mb-3" xs={12} md={6}>
            <Container>
              <Carousel className="mb-6">
                <Carousel.Item>
                  <Image src="/carolina_richard.jpeg" rounded fluid />
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="/church.jpeg" rounded fluid />
                </Carousel.Item>
                <Carousel.Item>
                  <Image src="/kavalierhaus.jpeg" rounded fluid />
                </Carousel.Item>
              </Carousel>
            </Container>
          </Col>
          <Col xs={12} md={6}>
            <Markdown>{T(invites.texts.schedule)}</Markdown>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <h2>{DE ? 'Teilnahme' : 'RSVP'}</h2>
          <Col>
            <Form>
              <Form.Label>{T(invites.texts.selectprogram)}</Form.Label>
              <InputGroup id="shortanswer" className="mb-3">
                <div key="paritipation" className="mb-3">
                  <Form.Check
                    label={T(invites.texts.noparticipation)}
                    name="group1"
                    type="radio"
                    id="no"
                    onChange={handleParticipationChange}
                    checked={participation === 'no'}
                  />
                  <Form.Check
                    label={T(invites.texts.participate) + ':'}
                    name="group1"
                    type="radio"
                    id="yes"
                    onChange={handleParticipationChange}
                    checked={participation === 'yes'}
                  />
                </div>
              </InputGroup>

              <Form.Label>
              {guest.num_kids > 0 ? T(invites.texts.howmanywithkids) : T(invites.texts.howmany)}
              </Form.Label>

              <ParticipationSelector
                time="13:00" program={DE ? "Trauung" : "Ceremony"}
                adults={rsvp.ceremony.adults} kids={rsvp.ceremony.kids} lang={DE ? 'de' : 'en'}
                maxAdults={guest.max_adults} maxKids={guest.max_kids}
                disabled={participation === 'no'}
                id="ceremony"
                onSelectionChange={handlePersonsChange} />
              <ParticipationSelector
                time="15:00" program={DE ? "Kaffee & Kuchen" : "Coffee & Cake"}
                adults={rsvp.coffee.adults} kids={rsvp.coffee.kids} lang={DE ? 'de' : 'en'}
                maxAdults={guest.max_adults} maxKids={guest.max_kids}
                disabled={participation === 'no'}
                id="coffee"
                onSelectionChange={handlePersonsChange} />
              <ParticipationSelector
                time="18:00" program={DE ? "Abendessen" : "Dinner"}
                adults={rsvp.dinner.adults} kids={rsvp.dinner.kids} lang={DE ? 'de' : 'en'}
                maxAdults={guest.max_adults} maxKids={guest.max_kids}
                disabled={participation === 'no'}
                id="dinner"
                onSelectionChange={handlePersonsChange} />

              <Form.Label>
                {guest.num_kids > 0 ? T(invites.texts.diet) : T(invites.texts.diet)}
              </Form.Label>
              <Form.Control as="textarea" rows={3} 
                value={rsvp.diet}
                onChange={(e) => handleTextChange(e, 'diet')}
                onBlur={(e) => sendRSVP(rsvp)}
                placeholder={participation === 'yes' ? (DE ? 'Bierallergie, lactofreundlich, gelegenheitsvegetarisch, etc.' : 
                  'Beer allergy, lactofriendly, veggi overeater, etc.') : ''}
                disabled={participation === 'no'}>
              </Form.Control>
            </Form>
          </Col>
        </Row>
      </section>
      <section>
        <h2>{DE ? 'Beiträge' : 'Contributions'}</h2>
        <Row>
          <Col>
            <Markdown>{T(invites.texts.contributions) + guest.contribution}</Markdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text>{DE ? "Art des Beitrags:" : "Contribution:" }</InputGroup.Text>
              <Form.Select
              id="contribution_type"
              aria-label="Art des Beitrags"
              onChange={(e) => handleContributionsChange(e, true)}
              value={rsvp.contribution.type}
              disabled={participation === 'no'}
              >
                <option value="none">{DE ? "gar nix" : "nothing at all"}</option>
                <option value="music">{DE ? "musikalisch" : "music"}</option>
                <option value="speech">{DE ? "rednerisch" : "speech"}</option>
                <option value="misc">{DE ? "sonstiges" : "other"}</option>
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={12} md={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text>{DE ? "Dauer:" : "Duration:"}</InputGroup.Text>
              <Form.Select
              id="contribution_duration"
              aria-label="Dauer"
              onChange={(e) => handleContributionsChange(e, true)}
              value={rsvp.contribution.duration}
              disabled={participation === 'no'}>
                <option value="na">{DE ? "weiß ich noch nicht" : "don't know yet"}</option>
                <option value="10">5-10 min</option>
                <option value="20">10-20 min</option>
                <option value="max">{DE ? "nicht endend" : "never ending"}</option>
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={12} md={12}>
            <Form.Label>{DE ? "Beschreibung:" : "Description:"}</Form.Label>
            <Form.Control as="textarea" rows={3}
              id="contribution_description"
              onChange={(e) => handleContributionsChange(e, false)}
              onBlur={(e) => sendRSVP(rsvp)}
              value={rsvp.contribution.description}
              disabled={participation === 'no'}
              placeholder={participation === 'yes' ? (DE ? 'Tanzen, Singen, auf dem Kamm blasen, peinliche Fotos ausgraben und teilen, oder schöne Musik, usw.' : 
                'Dancing, singing, playing the comb, digging up and sharing embarrassing photos, or beautiful music, etc.') : ''}
              />
          </Col>
        </Row>
      </section>
      <section>
        <h2>{DE ? "Anfahrt & Mitfahrgelegenheit" : "Directions & Carpooling"}</h2>
        <Row>
          <Col>
            <MapLocation />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Markdown>{T(invites.texts.transport)}</Markdown>
            <Form.Label>{DE ? "Für die Hin- und Rückfahrt zwischen Potsdam ⇄ Caputh:" : 
                              "For the round trip Potsdam ⇄ Caputh:"}
            </Form.Label>
          </Col>
          <Col xs={12} md={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text>{DE ? (guest.num_adults > 1 ? "Wir" : "Ich") : (guest.num_adults > 1 ? "We" : "I")}</InputGroup.Text>
              <Form.Select id="transport_type" 
              value={rsvp.transport}
              onChange={handleTransportNeedsChanged}
              disabled={participation === 'no'}>
                <option value="none">{DE ? (guest.num_adults > 1 ? 'möchten unabhängig sein.' : 'möchte unabhängig sein.') : 'want to be independent.'}</option>
                <option value="taxi">{DE ? (guest.num_adults > 1 ? 'möchten ein Taxi teilen' : 'möchte ein Taxi teilen') : 'want to share a taxi'}</option>
                <option value="offer">{DE ? (guest.num_adults > 1 ? 'bieten eine Mitfahrgelegenheit' : 'biete eine Mitfahrgelegenheit') : "can offer a ride"}</option>
                <option value="need">{DE ? (guest.num_adults > 1 ? 'suchen eine Mitfahrgelegenheit' : 'suche eine Mitfahrgelegenheit') : "need a ride"}</option>
              </Form.Select>
            </InputGroup>
          </Col>
          {rsvp.transport !== 'none' && (
          <Col xs={12} md={6}>
              <InputGroup className="mb-3">
                <InputGroup.Text>{DE ? "für" : "for"}</InputGroup.Text>
                <Form.Select
                id="transport_seats"
                aria-label={DE ? "Anzahl Plätze" : "Number of seats"}
                value={rsvp.transport_seats}
                disabled={rsvp.transport === 'none' || participation === 'no'}
                onChange={handleTransportSeatsChanged}
                >
                  {Array.from({ length: 8 }, (_, index) => index).map((value) => (
                    <option key={value} value={value}>{value} {DE ? "Personen" : "people"}</option>
                  ))}
                </Form.Select>
              </InputGroup>
          </Col>
          )}
        </Row>
      </section>
      <section>
        <h2>{DE ? "Unterkunft" : "Accomodation"}</h2>
        <Row>
          <Col>
            <Markdown>{T(invites.texts.accomodation)}</Markdown>
          </Col>
        </Row>
      </section>
      <section>
        <h2>{DE ? "Lieblingssong" : "Favourite Song"}</h2>
        <Col>
          <Markdown>{T(invites.texts.songs)}</Markdown>
          <Form.Control id="songs" as="textarea" rows={3} 
            placeholder={DE ? 'Wunschliste' : 'wishlist'}
            value={rsvp.song}
            onChange={(e) => handleTextChange(e, 'song')}
            onBlur={(e) => sendRSVP(rsvp)}
            disabled={participation === 'no'}>
          </Form.Control>
        </Col>
      </section>
      <section>
        <h2>{DE ? "Garderobe" : "Dresscode"}</h2>
        <Row>
          <Col>
            <Markdown>{T(invites.texts.dresscode)}</Markdown>
          </Col>
        </Row>
      </section>
      <section>
        <h2>{DE ? "Geschenke" : "Gifts"}</h2>
        <Row>
          <Col>
            <Markdown>{T(invites.texts.gifts)}</Markdown>
          </Col>
        </Row>
      </section>
      <section>
        <Markdown>{T(invites.texts.finalize)}</Markdown>
        <div className="d-flex justify-content-end">
            <button className="btn btn-primary mb-3" 
            onClick={() => { const newRsvp = {...rsvp, finalized: true}; setRsvp(newRsvp); sendRSVP(newRsvp);}}>
              {DE ? "Formular speichern" : "Save form"}
            </button>
        </div>
        {showResult === 'ok' && (
        <Alert variant="success">{DE ? "Formular gespeichert." : "The form has beed saved."}</Alert>
        )}
        {showResult === 'failed' && (
        <Alert variant="danger">{DE ? "Fehler beim Absenden. Bitte melde dich direkt bei uns." : "Error during processing. Please reach out to us."}</Alert>
        )}
      </section>
    </Container>
  );

  return dom;
}

export default App;
