import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Markdown from 'react-markdown'

import invites from './invites.json';
import './App.css';

function Info({lang}) {
    const DE = lang === 'de';
    const T = (the_text) => {
        if (DE) {
          if (typeof the_text.de === 'string') {
            return the_text.de;
          } else if (typeof the_text.de === 'object' && ('plural' in the_text.de || 'singular' in the_text.de)) {
            return the_text.de.plural
          } else {
            return '';
          }
        } else {
          return the_text.en;
        }
      }

    return (
        <Container fluid className="p-3 container main-content rounded bg-white">
            <header className='py-3 mb-4 border-bottom'>
            <div className='container d-flex flex-wrap justify-content-center'>
                <h1 className="header break-word">{DE ? 'Wichtige Hochzeitsinformationen' : 'Important wedding information'}</h1>
            </div>
            <div className='container d-flex flex-wrap justify-content-center'>
                <h3 className="subheader">{DE ? 'für Freitag, den 8. November 2024' : 'for November 8, 2024'}</h3>
            </div>
            </header>
            <section>
                <Markdown>{ DE ?`
Liebe Gäste,
nun ist es gar nicht mehr so lange bis zur Hochzeit! Wir freuen uns schon sehr darauf euch zu sehen.
Vorab möchten wir sehr wichtige Informationen mit euch teilen.

##### Sehr wichtige Informationen

1. Bitte kommt pünktlich zur Trauung. Es handelt sich um eine standesamtliche Trauung, die um 13:00 Uhr nach deutschem Beamtentum ganz pünktlich beginnen muss. Auch wird es mit den Parkplätzen nicht so einfach sein und aus Erfahrung wissen wir, dass der Verkehr in Richtung Potsdam momentan stark sein kann. Bitte plant also bei der Anreise genug Zeit ein.
2. Wir wurden vom Kirchenverein gebeten unsere Gäste darauf hinzuweisen, keine künstlichen Blumen oder Kofetti zu streuen. Frische Blüten sind aber erlaubt.
3. Gerne dürft ihr Fotos machen und hinterher zwischen uns und euch teilen. Allerdings bitten wir darum, keine Social-Media-Beiträge von dem Tag zu posten (Instagram, Facebook, etc.)
4. Bitte haltet euch nach der Trauung für ein Gruppenfoto vor der Kirche bereit. Zur Belohnung gibt es danach einen Sektempfang, wenn das Wetter mitspielt.
5. Es gibt im Kavalierhaus einen Raum, den wir nach Belieben in ein Kinderzimmer verwandeln dürfen. Bringt also gerne Spielzeug mit.
6. Bitte esst und trinkt so viel ihr wollt. Wir haben eine Getränke-Flatrate :-)

##### Nach der Hochzeit

Wir werden im Kavalierhaus übernachten und dann am Samstag gegen 11 Uhr in Potsdam gemütlich brunchen.
Insbesondere angereiste Gäste, Familie und jeder der gerne dabei sein möchte, kann gerne dazu kommen.
Bitte meldet euch bei Interesse. Ein Café suchen wir in der kommenden Woche aus, je nachdem wie groß die Gruppe wird.
` : `
Dear guests,
the wedding is not far away! We are very much looking forward to seeing you.
We would like to share some very important information with you in advance.

##### Very important information

1. Please arrive on time for the ceremony. It is a civil ceremony that must start at 1:00 p.m. according to German officialdom. Also, parking will not be easy and from experience we know that traffic towards Potsdam can be heavy. So please allow enough time for your journey.
2. We have been asked by the church association to inform our guests not to scatter artificial flowers or confetti. Fresh flowers are allowed.
3. You are welcome to take photos and share them with us afterwards, but please do not post any social media posts from the day (Instagram, Facebook, etc.)
4. Please be ready for a group photo in front of the church after the ceremony. As a reward, there will be a champagne reception afterwards, weather permitting.
5. There is a room in the Kavalierhaus that we can turn into a children's room as we like. So feel free to bring toys.
6. Please eat and drink as much as you like. We have an open bar :-)

##### After the wedding

We will stay at the Kavalierhaus and then have a cozy brunch in Potsdam on Saturday around 11 a.m.
Especially guests who have traveled, family and anyone who would like to join us is welcome to come along.
Please let us know if you are interested. We will choose a café next week, depending on how large the group is.
`}
                </Markdown>
            </section>
            <section>
                <Row>
                <Col className="mb-3" xs={12} md={6}>
                    <Container>
                    <Carousel className="mb-6">
                        <Carousel.Item>
                        <Image src="/carolina_richard.jpeg" rounded fluid />
                        </Carousel.Item>
                        <Carousel.Item>
                        <Image src="/church.jpeg" rounded fluid />
                        </Carousel.Item>
                        <Carousel.Item>
                        <Image src="/kavalierhaus.jpeg" rounded fluid />
                        </Carousel.Item>
                    </Carousel>
                    </Container>
                </Col>
                <Col xs={12} md={6}>
                    <Markdown>{T(invites.texts.schedule)}</Markdown>
                </Col>
                </Row>
            </section>
        </Container>
    );
}

export default Info;
